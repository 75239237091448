import React, { useContext } from "react";
import { cn } from "../lib/helpers";
import { GetStarted, SectionWrapper } from "../components/sections";
import { CardGrid } from "../components/card/card-grid";
import { InlineCardQuote } from "../components/inline-card";
import { BackToZone } from "../components/back-to-zone";
import { SiteContext } from "../components/global/site-context";
import {
  IntegrationsTiles,
  TableOfContentsSection,
} from "../components/playbook-page";
import PortableText from "../components/portableText";
import { ImageBuilder } from "../components/global/image-builder";

import * as styles from "../components/portableText.module.css";
import * as globalStyles from "../components/global/global.module.css";

export const StoryPage = ({
  title,
  _rawBody,
  company,
  quote,
  businessModel,
  teams,
  useCases,
  integrations,
  suggested,
}) => {
  const site = useContext(SiteContext);

  return (
    <>
      <SectionWrapper
        container="large"
        background="gradientLavender"
        name="header"
        padding="tight"
      >
        <div className="relative">
          <div
            className={cn(
              "w-1/3 absolute top-1/2 left-1/2 h-1/3 ml-12",
              globalStyles.radialOrange
            )}
          ></div>
          <div className="mt-4">
            <BackToZone to="/customers/" text="See all customer stories" />
          </div>
          <div className="grid md:grid-cols-3/5-2/5 gap-6 xl:gap-12 relative">
            <div>
              {company && (
                <ImageBuilder
                  image={company.mainImage}
                  quality={100}
                  height={100}
                  style={{ maxHeight: "100px" }}
                  alt={company.title}
                />
              )}
              <h1 className="text-4xl md:text-6xl font-semibold mt-2 md:mr-6 xl:mr-24">
                {title}
              </h1>
            </div>
            <div className="mt-6 flex items-center">
              <InlineCardQuote
                node={quote}
                skipLogo={true}
                simpleLayout={true}
              />
            </div>
          </div>
        </div>
        <div className="relative mb-8">
          <MetaDetails {...{ businessModel, teams, useCases, integrations }} />
        </div>
      </SectionWrapper>

      {_rawBody ? (
        _rawBody?.filter((b) => b._type === "anchor").length > 0 ? (
          <SectionWrapper container="large">
            <TableOfContentsSection {...{ _rawBody }} />
          </SectionWrapper>
        ) : (
          <SectionWrapper container="thin">
            <PortableText blocks={_rawBody} />
          </SectionWrapper>
        )
      ) : null}

      <SectionWrapper
        header={site.token("suggestions")}
        layout="centered"
        container="thin"
      >
        <CardGrid
          nodes={suggested}
          browseMoreHref="/customers/"
          browseMoreText="See all customer stories"
          hideLabels={false}
          maxColumns={2}
          makeThumbnail={true}
          showSummary={true}
          allowSummary={true}
          panels={true}
          attribution={{ action: "suggestion" }}
        />
      </SectionWrapper>

      <SectionWrapper container="large">
        <GetStarted />
      </SectionWrapper>
    </>
  );
};

const MetaDetails = ({ businessModel, teams, useCases, integrations }) =>
  [businessModel, teams, useCases, integrations].find((n) => n.length > 0) ? (
    <ul className="mt-12 p-2 grid grid-cols-2 lg:grid-cols-4 gap-2 bg-white border border-dark-10 rounded-lg text-sm font-medium">
      <li className="bg-dark-2 border border-dark-10 p-4 lg:p-6 rounded-lg">
        <p className="text-secondary mb-3">Business model</p>
        <ul className={styles.checkListInline}>
          {businessModel.map((n) => (
            <li>{n}</li>
          ))}
        </ul>
      </li>
      <li className="bg-dark-2 border border-dark-10 p-4 lg:p-6 rounded-lg">
        <p className="text-secondary mb-3">Teams</p>
        <ul className={styles.checkListInline}>
          {teams.map((n) => (
            <li>{n}</li>
          ))}
        </ul>
      </li>
      <li className="bg-dark-2 border border-dark-10 p-4 lg:p-6 rounded-lg">
        <p className="text-secondary mb-3">Use cases</p>
        <ul className={styles.checkListInline}>
          {useCases.map((n) => (
            <li>{n}</li>
          ))}
        </ul>
      </li>
      <li className="bg-dark-2 border border-dark-10 p-4 lg:p-6 rounded-lg">
        <p className="text-secondary mb-3">Key signals</p>
        <div className="flex flex-col">
          <IntegrationsTiles integrations={integrations} />
        </div>
      </li>
    </ul>
  ) : null;
